import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from 'src/app/models/paging/page';
import { UserExtended } from 'src/app/models/users/user-extended.model';
import { environment } from 'src/environments/environment';
import { AuthoritiesEnum, User } from '../../models/users/user.model';
import { FiltersModel } from '../../models/paging/filters.model';
import { DefaultPageable, PageableModel } from '../../models/paging/pageable-model';
import { Events } from '../events/events.service';
import { UserRegister } from 'src/app/models/users/user-register.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private httpClient: HttpClient,
    protected events: Events,
  ) {}

  findAllUsers(filters: FiltersModel, pageable: PageableModel): Observable<Page<UserExtended>> {
    const paramsPage = pageable ? pageable.toParams() : DefaultPageable.getPageable().toParams();

    const generalFilters = filters.getModelExcludeProperties(['categories', 'sports']);
    const generalParamFilters = generalFilters.toParamsWithProperty();

    const categoriesFilters = filters.getModelIncludeProperties(['categories']);
    const categoriesParamFilters = categoriesFilters.toParams('searchCategories');

    const sportsFilters = filters.getModelIncludeProperties(['sports']);
    const sportsParamFilters = sportsFilters.toParams('searchSports');

    const queryParam = [paramsPage, generalParamFilters, categoriesParamFilters, sportsParamFilters]
      .filter((param) => param != null)
      .join('&');

    return this.httpClient.get<Page<UserExtended>>(environment.apiUrl + '/users?' + queryParam);
  }

  findAllUsersWithAuthority(authorities: AuthoritiesEnum[]): Observable<User[]> {
    return this.httpClient.post<User[]>(environment.apiUrl + '/users/fromAuthority', authorities);
  }

  /**
   * Send a POST request to our signup endpoint with the data
   * the user entered on the form.
   */
  signup(user: UserRegister): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrl + '/register', user);
  }

  /**
   * Send a POST request to add user
   */
  addUser(user: User): Observable<User> {
    return this.httpClient.post<User>(environment.apiUrl + '/users', user);
  }

  /**
   * Send a PUT request to update user
   */
  updateUser(user: User): Observable<User> {
    return this.httpClient.put<User>('/users', user);
  }

  /**
   * Send a GET request to the activation account endpoint
   */
  accountActivation(key: string): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.apiUrl + '/activate?key=' + key);
  }

  changePassword(password: string): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrl + '/account/change-password', password);
  }

  resetPassword(mail: string): Observable<void> {
    return this.httpClient.post<void>(environment.apiUrl + '/account/reset-password/init', mail);
  }

  changePasswordAfterInit(password: string, key: string): Observable<void> {
    const payload = { key, newPassword: password };
    return this.httpClient.post<void>(environment.apiUrl + '/account/reset-password/finish', payload);
  }

  acceptCgu(): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrl + '/account/accept-cgu', {});
  }

  accountStatus(user: User): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrl + '/account/status', user);
  }

  setTokenNotification(token: string | null): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrl + '/account/token-notification', {
      tokenNotification: token,
    });
  }

  getUserById(userId: number): Observable<User> {
    return this.httpClient.get<User>(environment.apiUrl + `/user/${userId}`);
  }
}
